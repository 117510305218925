::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #fbc07c;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 5px;
  grid-auto-flow: dense;
  list-style: none;
  margin: 1em auto;
  padding: 0;
  overflow-y: scroll;
}

.gallery li.landscape {
  grid-column-end: span 2;
}

.gallery li.landscaper {
  grid-column-end: span 3;
}

.gallery li img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
