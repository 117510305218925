.App {
  overflow-y: hidden;
  height: 100vh;
}

button {
  background: none !important;
  border: none;
  padding: 0 !important;
}

.menuItem {
  cursor: pointer;
  font-size: 1.5em;
  font-family: Courier New, monospace;
}

.overflow-bar {
  overflow-y: scroll;
  max-height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-bar::-webkit-scrollbar {
  display: none;
}

.album {
  padding-bottom: 100%;
  border: 0;
  background-color: transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
