* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  min-height: 100vh !important;
  height: 100vh;
}

body {
  overflow: hidden;
}

.container-fluid {
  margin: 0 auto;
  min-height: 100%;
  height: 100%;
  padding: 0;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.nav-bar {
  width: 18em;
  background: url(./picto/sidebar.png);
  background-size: cover;
}

.grayout img {
  filter: grayscale(70%);
}

.gallery-picto img {
  border: 2px solid white;
  border-radius: 5px;
}

.nav-link,
a,
body,
.pink {
  color: plum !important;
}

.right-column {
  right: 0;
}

.center-area {
  background: url(./picto/layout.png);
}

.center-continer {
  min-height: 500px;
  height: 85vh;
  max-height: 85vh;
}

.center-world {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  pointer-events: none;
}

.pink-world {
  margin-top: 5px;
  background-color: rgb(254, 240, 255);
  border-radius: 1.5em !important;
  height: 99%;
}

#twitch-embed {
  /* unused! */
  height: 500px;
  width: 100%;
  border-radius: 1.5em !important;
}

.menu-button-container {
  margin-top: 2rem;
}

.menu-button {
  margin-top: -3px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.menu-button:hover {
  transform: translateY(-2px);
  transition: 100ms ease-in-out;
}

.bottom-bar {
  bottom: 0;
  z-index: 100;
  overflow-x: visible;
}

.logo {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  left: 0;
  bottom: -15px;
  cursor: pointer;
}

.logo-img {
  height: 150px;
  width: 527px;
  background: url(./picto/logopinkfinal.png) 50% 50% / cover no-repeat;
  z-index: 3;
}

.logo-img-mobile {
  height: 200px;
  width: 100vw;
  background: url(./picto/logopinkfinal.png) 50% 50% / contain no-repeat;
}


.copyright {
  bottom: 15px;
  right: 0;
  color: lightgrey;
}

.luminacam {
  background: url(./picto/luminajpeg.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom -20% right 0%;
  border-radius: 33px;
}

.luminacam img {
  width: 100%;
  cursor: url(./picto/hand.png), auto;
}

.side-area {
  max-width: 20%;
}

.tweets-container {
  position: absolute;
  margin-top: -15px;
  padding-bottom: 0.2rem;
  height: 335px;
}

.tweets {
  padding-left: 10px;
  padding-right: 10px;
  background: white;
  height: 100%;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}

@media (min-width: 575px) and (max-width: 1024px) {
  body {
    overflow-x: scroll !important;
    width: 1000px !important;
  }
}

.guestbook-overlay {
  position: absolute;
  pointer-events: none;
  height: 320px;
  z-index: 1;
}

.guestbook-overlay img {
  width: 100%;
}

.teller-container:hover {

  animation-name: teller;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes teller {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2px);
  }

  100% {
    transform: translateY(0);
  }
}

.poin {
  border-radius: 10px;
}

.poin:hover,
.poin:active {
  animation-name: poinie;
  animation-duration: 1s;
  /* animation-iteration-count: infinite; */
  animation-timing-function: linear;
}

@keyframes poinie {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(-1deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0);
  }
}

.wave {
  text-shadow: 2px 2px 4px lightpink;
}

.wave span {
  display: inline-block;
  animation-name: wave;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: calc(var(--animation-order) * 50ms);
}

@keyframes wave {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-2px);
  }
}
